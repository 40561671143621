<template>
  <div>
    <div class="money">
      <p style="margin-right: 10px;">当前账户余额: &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</p>
      <p style="color: red; font-size: 24px;">¥ {{ balance }}</p>
    </div>
    <en-table-layout :tableData="pageData.data" :loading="loading" :pageOtherHeight="pageOtherHeight"
      @selection-change="handleSelectionChange">
      <template slot="toolbar">
        <el-form-item label="交易类型">
          <el-select style="width:130px" size="medium" v-model="params.flow_type" placeholder="选择交易类型" clearable>
            <el-option v-for="item in list1" :key="item.code" :label="item.name" :value="item.code"></el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="时间范围">
          <el-date-picker size="medium" :default-time="['00:00:00', '23:59:59']" value-format="timestamp"
            style="width: 220px" v-model="daterangeDate" type="daterange" @change="daterangeDateChange"
            range-separator="-" start-placeholder="开始日期" end-placeholder="结束日期" :picker-options="pickerOptions"
            clearable />
        </el-form-item>

        <el-form-item label="关键词">
          <el-input placeholder="请输入关键词" size="medium" v-model="params.sn_query" clearable style="width: 260px">
            <el-select style="width: 120px" slot="prepend" size="medium" v-model="params.trade_sn" placeholder="选择关键词"
              clearable>
              <el-option v-for="item in list2" :key="item.value" :label="item.label" :value="item.value">
              </el-option>
            </el-select>
          </el-input>
        </el-form-item>

        <el-button type="primary" size="small" :loading="importLoading" @click="getList()">
          搜索
        </el-button>

        <div class="col"></div>

        <el-button type="primary" size="small" :loading="importLoading" @click="downZd()">
          导出
        </el-button>
      </template>

      <template slot="table-columns">
        <el-table-column label="交易日期" width="150">
          <template slot-scope="scope">
            {{ scope.row.create_date | unixToDate('yyyy-MM-dd hh:mm:ss') }}
          </template>
        </el-table-column>

        <el-table-column label="流水号" width="200">
          <template slot-scope="scope">{{ scope.row.sn }}</template>
        </el-table-column>

        <el-table-column label="交易类型" props="flow_type" :formatter="flow_type_value" width="80">
        </el-table-column>
        <el-table-column label="交易内容/备注" min-width="400">
          <template slot-scope="scope">
            {{ scope.row.order_content }}
          </template>
        </el-table-column>

        <el-table-column label="站点账户变动（元）" width="150">
          <template slot-scope="scope">
            {{ scope.row.revenue_expense_amount }}
          </template>
        </el-table-column>

        <!--      <el-table-column label="站点现金账户变动（元）" width="180">-->
        <!--        <template slot-scope="scope">-->
        <!--          {{ scope.row.revenue_expense_cash }}-->
        <!--        </template>-->
        <!--      </el-table-column>-->

        <el-table-column label="操作" width="80" fixed="right">
          <template slot-scope="scope">
            <el-button size="mini" type="primary" v-if="scope.row.flow_type === 2" @click="handleStockGoods(scope.row)">
              详情
            </el-button>
          </template>
        </el-table-column>
      </template>
      <el-pagination slot="pagination" @size-change="handlePageSizeChange" @current-change="handlePageCurrentChange"
        :current-page="pageData.page_no" :page-sizes="MixinPageSizes" :layout="MixinTableLayout" background
        :page-size="pageData.page_size" :total="pageData.data_total">
      </el-pagination>
    </en-table-layout>
  </div>
</template>

<script>
import * as API_account from '@/api/account';
import { handleDownload } from '@/utils';
import { Foundation } from '@/../ui-utils';
import EnTableLayout from '../../../../ui-components/TableLayout/src/main';

export default {
  name: 'finance-deal-detailed',
  components: { EnTableLayout },
  data () {
    return {
      activeName: 1,
      txShow: false,
      /** 列表loading状态 */
      loading: false,
      importLoading: false,
      idsList: [],
      txInfo: {},
      txRules: {
        a4a4: [{
          required: true,
          message: '请输入礼包名称',
          trigger: 'blur'
        }],
      },
      // 流水类型 0：充值，1：销售，2：采购，3：运费，4：提现，5：提现手续费，6：自有订单服务费，7：退款，8：购买短信费用，9：企业等级购买费用，10：其他
      list1: [],
      list2: [
        {
          value: 1,
          label: '根据流水号'
        },
        {
          value: 2,
          label: '根据订单号'
        },
      ],

      /** 列表参数 */
      params: {
        page_no: 1,
        page_size: 20,
        shop_name: '',
        seller_name: '',
        start_time: '',
        end_time: '',
        logi_id: '',
        pay_type: '',
        ca_type: 0,
        trade_sn: '',
        sn_query: '',
      },

      daterangeDate: '',

      /** 列表数据 */
      /** 列表分页数据 */
      pageData: { data: [] },

      /** 快递列表 */
      logiList: [],

      /** 店铺信息 */
      shopInfo: this.$store.getters.shopInfo,
      pickerOptions: {
        shortcuts: [
          {
            text: '今天',
            onClick (picker) {
              const end = new Date();
              const start = new Date();
              // start.setTime(start.getTime() - 3600 * 1000 * 24 * 0);
              start.setTime(start.getTime());
              picker.$emit('pick', [start, end]);
            },
          },
          {
            text: '近7日',
            onClick (picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit('pick', [start, end]);
            },
          },
          {
            text: '近15日',
            onClick (picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 15);
              picker.$emit('pick', [start, end]);
            },
          },
          {
            text: '近30日',
            onClick (picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit('pick', [start, end]);
            },
          },
        ],
      },
      balance: "",
      pageOtherHeight: 0
    };
  },
  activated () {
    this.GET_AccountList();
  },
  mounted () {
    this.GET_getFlowTypeList();
    this.GET_AccountList();
    API_account.getShopBalance().then(res => {
      this.balance = res.balance_account
    })
    this.pageOtherHeight = this.$el.querySelector('.money').clientHeight + 20
  },
  methods: {
    daterangeDateChange (val) {
      this.params.start_time = val ? val[0] / 1000 : '';
      this.params.end_time = val ? val[1] / 1000 : '';
    },
    flow_type_value (row) {
      let obj = this.list1.find(value => {
        if (parseInt(value.code) === row.flow_type) {
          return true;
        }
      });
      if (obj) {
        return obj.name;
      }
    },
    getList () {
      this.GET_AccountList();
    },
    downZd (row) {
      if (!row) {
        this.importLoading = true;
      }
      if (this.shopInfo.shop_type === 2) {
        this.params.settle_status = 0;
      } else {
        this.params.shop_settle_status = 0;
      }
      delete this.params.page_no;
      delete this.params.page_size;
      API_account.getAccountFlowList(this.params).then((response) => {
        if (response.code != -1) {
          response.data.forEach((item) => {
            item.create_date = Foundation.unixToDate(
              item.create_date,
              'yyyy-MM-dd hh:mm:ss'
            );
            item.flow_type = this.list1.find((value, index, arr) => {
              return item.flow_type === parseInt(value.code);
            }).name;
          });
          let tHeaders = [
            '交易日期',
            '流水号',
            '交易类型',
            // "来源站点",
            '交易内容/备注',
            '站点账户变动（元）',
            // '站点现金账户变动（元）',
            // "账户余额",
          ];
          let filterVals = [
            'create_date',
            'sn',
            'flow_type',
            // "user_name",
            'order_content',
            'revenue_expense_amount',
            // 'revenue_expense_cash',
            // "balance_account"
          ];
          handleDownload(response.data, tHeaders, filterVals, '财务明细账单');
          this.importLoading = false;
          this.params.page_no = 1
          this.params.page_size = 10
        } else {
          this.importLoading = false
          this.$message.error(response.data.message);
        }
      });
    },

    /**获取流水类型信息 */
    GET_getFlowTypeList () {
      API_account.getFlowTypeList({ use_type: 1 }).then((response) => {
        this.list1 = response.filter((item) => { return parseInt(item.code) <= 3 || parseInt(item.code) === 7; });
      });
    },
    /** 分页大小发生改变 */
    handlePageSizeChange (size) {
      this.params.page_size = size;
      this.GET_AccountList();
    },

    /** 分页页数发生改变 */
    handlePageCurrentChange (page) {
      this.params.page_no = page;
      this.GET_AccountList();
    },

    /** 分页大小发生改变 */
    handlePageSizeChange1 (size) {
      this.params.page_size = size;
      this.GET_AccountList();
    },

    /** 分页页数发生改变 */
    handlePageCurrentChange1 (page) {
      this.params.page_no = page;
      this.GET_AccountList();
    },

    /** 搜索事件触发 */
    searchEvent (data) {
      this.params = {
        ...this.params,
        keyword: data,
      };
      this.GET_AccountList();
    },

    GET_AccountList () {
      this.loading = true;

      if (this.shopInfo.shop_type === 2) {
        this.params.settle_status = 0;
      } else {
        this.params.shop_settle_status = 0;
      }

      API_account.getAccountFlowPage(this.params).then((response) => {
        this.loading = false;
        this.pageData = response;
      });
    },

    handleSelectionChange (val) {
      let ids = [];
      val.forEach((item) => {
        ids.push(item.order_id);
      });
      this.idsList = ids;
    },
    settlement () {
      if (this.idsList.length) {
        API_account.checkaccountAdd({
          orderId: this.idsList,
          caDirection: this.params.ca_type,
        }).then((res) => {
          this.$message.success('发起结算成功');
          this.GET_AccountList();
        });
      } else {
        this.$message.error('请勾选数据');
      }
    },
    handleStockGoods (row) {
      let route = this.$route.path.split('/')[2];
      this.$router.push({ path: `/combo-card-shop/order/${route}/detail/${row.trade_sn}` });
      // 按照原型开发，此处跳交易订单详情页面
      // this.$router.push({ path: `/combo-card-shop/finance/${route}/detail/${row.trade_sn}` });
    },
  },
};
</script>

<style lang="scss" scoped>
.money {
  height: 60px;
  line-height: 30px;
  padding: 15px 30px 0 30px;
  background: #FFFFFF;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
}

/deep/ .el-table-layout {
  height: 500px;
}
</style>








